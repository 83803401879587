import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@material-ui/core";
import { getCustomerCreateAccountStatus } from "../../Actions";

export default function SignInAlert(props) {
  const [userRegSwitchState, setUserRegSwitchState] = useState(false);

  useEffect(() => {
    getCustomerCreateAccountStatus().then((result) => {
      if (Number(result.data.data[0].app_value) === 1) {
        setUserRegSwitchState(true);
      } else {
        setUserRegSwitchState(false);
      }
    });
  }, [])

  return (
    <Dialog
      open={props.isOpen}
      onClose={() => props.onClose()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Account Required</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Typography variant="body">
            An account is required to add products to your favorites list or request to check out product
            samples. Please create an account or sign in to continue.
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => props.onClose()}>
          Close
        </Button>
        { userRegSwitchState ? (
            <Button 
              variant="contained" 
              onClick={() => {
                window.location.href = '/register';
                props.onClose();
              }}
            >
              Create Account
            </Button> 
          ) : undefined }
        <Button 
          variant="contained" 
          onClick={() => {
            window.location.href = '/signin';
            props.onClose();
          }}
          style={{backgroundColor: "#142e3e", color: "#28c4fc"}}
        >
          Sign In
        </Button>
      </DialogActions>
    </Dialog>
  );
};