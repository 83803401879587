import React, { useContext, useEffect, useState } from 'react';

// MUI
import {
  makeStyles,
  Divider,
  Button,
  Badge,
  IconButton,
  Tooltip,
  Typography,
  Toolbar,
  TextField,
} from '@material-ui/core';

// icons
import PrintIcon from '@material-ui/icons/Print';
import FilterListIcon from '@material-ui/icons/FilterList';

// styles
// import "../../styles/Products.css";

// actions
import { updatePrintQueue } from '../../../Actions';

// dependends
import { AlertContext } from '../../../Context/AlertContextProvider';
import { PrintQueueContext } from '../../../Context/PrintQueueContextProvider';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  button: {
    color: '#ff682c',
    background: '#EEEEEE',
    marginLeft: 50,
  },
  divider: {
    marginLeft: 50,
    background: '#000',
    width: 2,
  },
  title: {
    flexGrow: 1,
  },
  select: {
    marginLeft: 20,
  },
}));

const TableToolBar = (props) => {
  const classes = useStyles();
  const { numSelected, productIdList, showMergeList, refresh } = props;
  const { handleAlertOpen, setMessageType, setMessage } =
    useContext(AlertContext);
  const { setUpdate } = useContext(PrintQueueContext);
  const [selectedProd, setSelectedProd] = useState([]);
  useEffect(() => {}, [refresh]);

  const handleUpdatePrintQueue = (productIdList) => {
    // var uniqueProductIdList = [...new Set(productIdList)];
    updatePrintQueue(productIdList)
      .then(() => {
        setUpdate(true);
        setMessageType('success');
        setMessage('Print Queue Updated');
        handleAlertOpen();
      })
      .catch((err) => {
        setMessageType('error');
        setMessage('Error Updating Print Queue');
        handleAlertOpen();
      });
  };

  const handleProdSelectChange = (event, value) => {
    setSelectedProd(value);
  };

  return (
    <Toolbar>
      {numSelected > 0 ? (
        <Tooltip title="Add to printing queue">
          <IconButton
            onClick={(e) => handleUpdatePrintQueue(productIdList)}
            aria-label="delete"
          >
            <Badge badgeContent="+" overlap="rectangular">
              <PrintIcon />
            </Badge>
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
      {numSelected > 0 ? (
        <Typography
          // className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          // className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Products
        </Typography>
      )}
      {numSelected > 0 ? (
        <Divider orientation="vertical" flexItem className={classes.divider} />
      ) : (
        <></>
      )}
      {numSelected > 0 ? (
        <Button
          variant="contained"
          className={classes.button}
          onClick={showMergeList}
        >
          Merge Options
        </Button>
      ) : (
        <></>
      )}
    </Toolbar>
  );
};

export default TableToolBar;
