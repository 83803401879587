import React, { useContext, useEffect, useState } from "react";
import { getCustomerCheckoutStatus, getCustomerUserInfo, getUserCheckoutStatus, requestSampleCheckout, returnSample } from "../../Actions";
import SampleCheckoutDialog from "./SampleCheckoutDialog";
import SignInAlert from "./SignInAlert";
import { AlertContext } from "../../Context/AlertContextProvider";
import { Button, Chip } from "@material-ui/core";
import LibraryAddCheckIcon from '@material-ui/icons/LibraryAddCheck';
import '../styles/Product.css';
import CustomerSampleCheckoutDialog from "./CustomerSampleCheckoutDialog";

export default function SampleCheckouts(props) {
  
  const [isSignInAlertOpen, setIsSignInAlertOpen] = useState(false);
  const [checkoutId, setCheckoutId] = useState();
  const [checkoutList, setCheckoutList] = useState([]);
  const [isCheckoutDialogOpen, setIsCheckoutDialogOpen] = useState(false);
  const [isCustomerCheckoutDialogOpen, setIsCustomerCheckoutDialogOpen] = useState(false);
  const [userData, setUserData] = useState([]);

  const checkoutStatus = async () => {
    if (['a','ro','rw'].includes(props.user.access_type_cd)) {
      getUserCheckoutStatus(props.product.product_uuid)
      .then((result) => {
        setCheckoutList(result.data.additional_user_checkout_status);
        if (result.data.checkout_status !== '') {
          setCheckoutId(result.data.sample_checkout_id);
        }
      })
      .catch((err) => {
        console.log(err);
      })
    } else if (props.user.access_type_cd === 'c') {
      getCustomerCheckoutStatus(props.product.product_uuid)
      .then((result) => {
        setCheckoutList(result.data);
        setCheckoutId(result.data.sample_checkout_id);
      })
      .catch((err) => {
        console.log(err);
      })
    } 

    return;
  };

  const onClickCheckout = () => {
    if (['a','ro','rw'].includes(props.user.access_type_cd)) {
      setIsCheckoutDialogOpen(true);
    } else if (props.user.access_type_cd === 'c') {
      setIsCustomerCheckoutDialogOpen(true);
    } else {
      setIsSignInAlertOpen(true);
    }  
  };

  useEffect(() => {
    if (props.user.access_type_cd === undefined) {
      setCheckoutList([]);
    }

    getCustomerUserInfo()
      .then((result) => {
        setUserData(result.data.data);
      })
      .catch((err) => {
        console.log(err);
      });

    checkoutStatus();
  }, [])

  const deduppedOptions = [];
  
  props.product.options?.filter(o => !o.discontinued).forEach(option => {
    if (!deduppedOptions.find(i => i.product_sku_uuid === option.product_sku_uuid)) {
      deduppedOptions.push(option);
    }

    return;
  });

  if (props.areCheckoutsAllowed === 0) return <></>;

  return (
    <>
      <Button
        className="product__icon-buttons"
        onClick={onClickCheckout}
      >
        <Chip
          icon={
            <LibraryAddCheckIcon
              style={{ color: '#616161' }}
            />
          }
          label='Checkout Sample'
          variant="outlined"
          className="product__checkout_icon"
        />
      </Button>
      {props.user && props.user.access_type_cd !== 'c' ? (
        <SampleCheckoutDialog 
          isOpen={isCheckoutDialogOpen}
          productId={props.product.product_uuid}
          locationId={props.locationId}
          checkoutList={checkoutList}
          users={userData}
          user={props.user}
          options={deduppedOptions}
          checkoutId={checkoutId}
          checkoutIconLabel={'Checkout Sample'}
          checkoutIconColor={'#616161'}
          checkoutStatus={() => checkoutStatus()}
          checkoutStatusInd={'ci'}
          onClose={() => setIsCheckoutDialogOpen(false)}
        />
      ) : undefined}
      {props.user && props.user?.access_type_cd === 'c' ? (
        <CustomerSampleCheckoutDialog 
          isOpen={isCustomerCheckoutDialogOpen}
          checkoutList={checkoutList}
          user={props.user}
          product={props.product}
          options={deduppedOptions}
          checkoutStatus={() => checkoutStatus()}
          onClose={() => setIsCustomerCheckoutDialogOpen(false)}
        />
      ) : undefined}

        <SignInAlert 
          isOpen={isSignInAlertOpen}
          onClose={() => setIsSignInAlertOpen(false)}
        />

    </>
  )
}