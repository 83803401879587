import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// MUI
import {
  AppBar,
  Toolbar,
  Typography,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  List,
  ListSubheader,
  Button,
  Paper,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  TextField,
  TableFooter,
  TablePagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';

// Icons
import ColorLensIcon from '@material-ui/icons/ColorLens';
import Collapse from '@material-ui/core/Collapse';
import StyleIcon from '@material-ui/icons/Style';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PersonIcon from '@material-ui/icons/Person';

// Styles
import '../styles/Favorites.css';

// Actions
import { getAllLikes } from '../../Actions';
import UserDetails from './UserDetails';
import { sortObjectsByAttribute } from '../../Util/utilsFunctions';

const Favorites = () => {
  const [favs, setFavs] = useState([]);
  const [value, setValue] = useState(0);
  const [userFilter, setUserFilter] = useState(0);
  const [open, setOpen] = useState(undefined);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('product_like_id');
  const [custNameSort, setCustNameSort] = useState(false);
  const [styleNameSort, setStyleNameSort] = useState(false);
  const [styleNumberSort, setStyleNumberSort] = useState(false);
  const [colorSort, setColorSort] = useState(false);
  const [priceSort, setPriceSort] = useState(false);
  const [userOpen, setUserOpen] = useState(false);
  const [userId, setUserId] = useState();
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userPhone, setUserPhone] = useState('');
  const [userPicture, setUserPicture] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    let isMounted = true;
    getAllLikes()
      .then((result) => {
        if (isMounted) setFavs(result.data.likes);
      })
      .catch((err) => {
        console.log(err);
      });

    return () => {
      isMounted = false;
    };
  }, [value]);

  useEffect(() => {
    setPage(0);
  }, [rowsPerPage, userFilter, order, orderBy]);

  const handleSort = (e) => {
    switch (e) {
      case 'name':
        if (custNameSort === false) {
          setCustNameSort(true);
          setOrder('desc');
          setOrderBy(e);
          setStyleNameSort(false);
          setStyleNumberSort(false);
          setColorSort(false);
          setPriceSort(false);
        } else {
          setCustNameSort(false);
          setOrder('asc');
        }
        break;
      case 'style_name':
        if (styleNameSort === false) {
          setStyleNameSort(true);
          setOrder('desc');
          setOrderBy(e);
          setCustNameSort(false);
          setStyleNumberSort(false);
          setColorSort(false);
          setPriceSort(false);
        } else {
          setStyleNameSort(false);
          setOrder('asc');
        }
        break;
      case 'style_number':
        if (styleNumberSort === false) {
          setStyleNumberSort(true);
          setOrder('desc');
          setOrderBy(e);
          setCustNameSort(false);
          setCustNameSort(false);
          setColorSort(false);
          setPriceSort(false);
        } else {
          setStyleNumberSort(false);
          setOrder('asc');
        }
        break;
      case 'color_name':
        if (colorSort === false) {
          setColorSort(true);
          setOrder('desc');
          setOrderBy(e);
          setCustNameSort(false);
          setStyleNumberSort(false);
          setStyleNameSort(false);
          setPriceSort(false);
        } else {
          setColorSort(false);
          setOrder('asc');
        }
        break;
      case 'retail_price':
        if (priceSort === false) {
          setPriceSort(true);
          setOrder('desc');
          setOrderBy(e);
          setCustNameSort(false);
          setStyleNameSort(false);
          setColorSort(false);
          setStyleNumberSort(false);
        } else {
          setPriceSort(false);
          setOrder('asc');
        }
        break;
      default:
        setCustNameSort(false);
        setStyleNameSort(false);
        setColorSort(false);
        setStyleNumberSort(false);
        setPriceSort(false);
    }
  };

  const handleClick = (index) => {
    open === index ? setOpen(undefined) : setOpen(index);
  };

  //   const onClickfavorite = (product_id, product_sku) => {
  //     unlikeProduct(product_id, product_sku)
  //     .then(() => {
  //         setValue(value + 1);
  //     })
  //     .catch((err) => {
  //         console.log(err);
  //     });
  //   };

  const handleUserOpen = (userId, name, email, phone, picture) => {
    setUserId(userId);
    setUserName(name);
    setUserEmail(email);
    setUserPhone(phone);
    setUserPicture(picture);
    setUserOpen(true);
  };

  const handleUserClose = () => {
    setUserId(undefined);
    setUserOpen(false);
  };

  const renderUserDetails = () => {
    return (
      <Dialog
        open={userOpen}
        onClose={handleUserClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title">User Information</DialogTitle>
        <DialogContent>
          <UserDetails
            userId={userId}
            name={userName}
            email={userEmail}
            phone={userPhone}
            picture={userPicture}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleUserClose}
            variant="contained"
            style={{ margin: 10, width: 80 }}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderSearchList = (filteredList, view) => {
    const sortedList = filteredList.sort((prev, curr) =>
      sortObjectsByAttribute(prev, curr, 'asc', 'name')
    );
    const filterOptions = [];

    sortedList
      ?.filter((c) => !!c.name && (!!c.email || !!c.phone))
      .forEach((c) => {
        if (
          filterOptions.find((u) => u.email === c.email && u.phone === c.phone)
        )
          return;
        filterOptions.push({
          email: c.email,
          name: c.name,
          phone: c.phone,
        });
      });

    const autocompleteStyles = {
      display: 'inline-block',
      width: '100%',
      maxWidth: '400px',
      margin: view === 'f' ? '20px 15px' : '20px 0px',
    };

    return (
      <Autocomplete
        value={userFilter}
        onChange={(event, user) => {
          setUserFilter(user ?? null);
        }}
        options={filterOptions}
        getOptionLabel={(option) => option?.name ?? ''}
        getOptionSelected={(option, value) =>
          option?.email === value?.email && option?.phone === value?.phone
        }
        style={autocompleteStyles}
        renderOption={(option) => (
          <span
            style={{
              borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
              width: '100%',
              paddingBottom: '5px',
            }}
          >
            <b>{option.name}</b>
            <br></br>
            {option.email}
            <br></br>
            {option.phone}
          </span>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Filter by customer"
            variant="filled"
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password',
            }}
          />
        )}
      />
    );
  };

  const getFilteredList = (list) => {
    return list?.filter((c) => {
      if (!userFilter) return true;
      return c.email === userFilter.email;
    });
  };

  const favRows = (paginatedList, view) => {
    if (view === 'm') {
      return paginatedList?.map((fav, index) => (
        <List key={'mobile-' + fav.product_like_id}>
          <ListItem
            button
            onClick={() => handleClick(fav.product_like_id)}
            className="favorites__list-menu"
          >
            <StyleIcon style={{ marginRight: '20px', color: '#FF682C' }} />
            <ListItemText
              primary={fav.name}
              secondary={
                <Typography className="checkout__list-item-secondary">
                  {fav.style_name}
                </Typography>
              }
            />
            {open === fav.product_like_id ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          {open === fav.product_like_id ? (
            <Collapse in={true} timeout="auto" unmountOnExit>
              <List style={{ backgroundColor: '#FFF' }}>
                <ListItem>
                  <ListItemIcon>
                    <StyleIcon />
                  </ListItemIcon>
                  <Link
                    to={`/product/${fav.product_uuid}`}
                    className="favorite__links"
                    target="blank"
                  >
                    <ListItemText primary={fav.style_number} />
                  </Link>
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemIcon>
                    <ColorLensIcon />
                  </ListItemIcon>
                  <ListItemText primary={fav.color_name} />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemIcon>
                    <AttachMoneyIcon />
                  </ListItemIcon>
                  <ListItemText primary={fav.retail_price} />
                </ListItem>
                <Divider />
                <Button
                  onClick={() => {
                    handleUserOpen(
                      fav.user_id,
                      fav.name,
                      fav.email,
                      fav.phone,
                      fav.picture
                    );
                  }}
                  variant="text"
                  style={{ textDecoration: 'underline', marginLeft: '-10px' }}
                >
                  <ListItem>
                    <ListItemIcon>
                      <PersonIcon />
                    </ListItemIcon>
                    <ListItemText primary="View user details" />
                  </ListItem>
                </Button>
                <Divider />
                {/* <Divider />
              <ListItem>
                  <Button className="product__icon-buttons" 
                  // onClick={() => onClickfavorite(fav.product_uuid, fav.sku)}
                  >
                      <CancelIcon style={{color:"FF0000", marginLeft:-40}}/>
                      <Chip icon={<CloseIcon  style={{ color: favIconColor }}/>}
                      label={favIconLabel}
                      fontSize="large" 
                      className="product__like_icon" 
                      variant="outlined"
                      />
                  </Button>
                  <ListItemText primary="Unlike?" style={{marginLeft:-8}}/>
              </ListItem>
              <Divider />
              <ListItem>
                  <ListItemIcon>
                  <LinkIcon />
                  </ListItemIcon>
                  <Link to={`/product/${fav.product_uuid}`} className="favorite__links" target="blank">
                  <ListItemText primary={"View product"} />
                  </Link>
              </ListItem> */}
              </List>
            </Collapse>
          ) : (
            <></>
          )}
        </List>
      ));
    }

    return paginatedList?.map((fav, index) => (
      <TableRow key={'desktop-' + fav.product_like_id}>
        <TableCell>
          <Button
            onClick={() => {
              handleUserOpen(
                fav.user_id,
                fav.name,
                fav.email,
                fav.phone,
                fav.picture
              );
            }}
            variant="text"
            style={{ textDecoration: 'underline' }}
          >
            {fav.name}
          </Button>
        </TableCell>
        <TableCell>
          <Link
            to={`/product/${fav.product_uuid}`}
            className="favorite__links"
            target="blank"
          >
            {fav.style_name}
          </Link>
        </TableCell>
        <TableCell>{fav.style_number}</TableCell>
        <TableCell>{fav.color_name}</TableCell>
        <TableCell>{fav.retail_price}</TableCell>
        {/* <TableCell>
        <Button className="product__icon-buttons" 
        // onClick={() => onClickfavorite(fav.product_uuid, fav.sku)}
        >
            <Chip icon={<CloseIcon  style={{ color: favIconColor }}/>}
            label={favIconLabel}
            fontSize="large" 
            className="product__like_icon" 
            variant="outlined"
            />
        </Button>
        </TableCell> */}
      </TableRow>
    ));
  };

  const handleChangePage = (e, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value));
  };

  const paginationControls = (filteredList, view) => {
    const rowsPerPageOptions = [5, 10, 25];

    if (view === 'm') {
      const buttonStyles = {
        backgroundColor: 'rgba(0, 0, 0, 0.09)',
        margin: '0px 0px 0px 10px',
      };
      const isFirstPage = page === 0;
      const isLastPage = (page + 1) * rowsPerPage >= filteredList.length;
      const currentStart = Math.max(page * rowsPerPage, 1);
      const currentEnd = Math.min(
        page * rowsPerPage + rowsPerPage,
        filteredList.length
      );

      return (
        <div style={{ margin: '0px 0px 20px', maxWidth: '400px' }}>
          <FormControl>
            <InputLabel id="rows-per-page-label">Rows per page</InputLabel>
            <Select
              labelId="rows-per-page-label"
              id="rows-per-page"
              value={rowsPerPage}
              style={{ width: '125px' }}
              variant="filled"
              onChange={(e) => setRowsPerPage(e.target.value)}
            >
              {rowsPerPageOptions.map((o) => {
                return (
                  <MenuItem key={o} value={o}>
                    {o}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <div style={{ float: 'right' }}>
            <Typography
              style={{
                display: 'inline-block',
                verticalAlign: 'middle',
                margin: '0px',
                textAlign: 'center',
              }}
            >
              {currentStart} - {currentEnd} of {filteredList.length}
            </Typography>
            <IconButton
              variant="text"
              disabled={isFirstPage}
              style={buttonStyles}
              onClick={() => setPage(isFirstPage ? 0 : page - 1)}
            >
              <ChevronLeft />
            </IconButton>
            <IconButton
              variant="text"
              disabled={isLastPage}
              style={buttonStyles}
              onClick={() => setPage(isLastPage ? page : page + 1)}
            >
              <ChevronRight />
            </IconButton>
          </div>
        </div>
      );
    }

    return (
      <TableRow>
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          colSpan={5}
          count={filteredList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: { 'aria-label': 'rows per page' },
            native: true,
          }}
          style={{ borderTop: '1px solid rgba(224, 224, 224, 1)' }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableRow>
    );
  };

  if (!favs.length)
    return (
      <div>
        <AppBar position="static">
          <Toolbar variant="dense" className="favorites__header">
            <Typography variant="h6" color="inherit">
              Customer Favorites
            </Typography>
          </Toolbar>
        </AppBar>
        <div className="link-container">
          <Typography variant="h5">There are no customer favorites</Typography>
        </div>
      </div>
    );

  const filteredFavsList = getFilteredList(favs);
  const sortedList = filteredFavsList.sort((prev, curr) =>
    sortObjectsByAttribute(prev, curr, order, orderBy)
  );
  const paginatedRows = sortedList.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <div>
      {renderUserDetails()}
      {/* Full resolution */}
      <div className="pending__container-full">
        <AppBar position="static">
          <Toolbar variant="dense" className="favorites__header">
            <Typography variant="h6" color="inherit">
              Customer Favorites
            </Typography>
          </Toolbar>
        </AppBar>
        <Paper>
          {renderSearchList(filteredFavsList, 'f')}
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="h5" className="fav-header-text">
                    Customer Name
                  </Typography>
                  <Button variant="text" onClick={() => handleSort('name')}>
                    {custNameSort === true ? <ExpandLess /> : <ExpandMore />}
                  </Button>
                </TableCell>
                <TableCell>
                  <Typography variant="h5" className="fav-header-text">
                    Style Name
                  </Typography>
                  <Button
                    variant="text"
                    onClick={() => handleSort('style_name')}
                  >
                    {styleNameSort === true ? <ExpandLess /> : <ExpandMore />}
                  </Button>
                </TableCell>
                <TableCell>
                  <Typography variant="h5" className="fav-header-text">
                    Style Number
                  </Typography>
                  <Button
                    variant="text"
                    onClick={() => handleSort('style_number')}
                  >
                    {styleNumberSort === true ? <ExpandLess /> : <ExpandMore />}
                  </Button>
                </TableCell>
                <TableCell>
                  <Typography variant="h5" className="fav-header-text">
                    Color/Option
                  </Typography>
                  <Button
                    variant="text"
                    onClick={() => handleSort('color_name')}
                  >
                    {colorSort === true ? <ExpandLess /> : <ExpandMore />}
                  </Button>
                </TableCell>
                <TableCell>
                  <Typography variant="h5" className="fav-header-text">
                    List Price
                  </Typography>
                  {/* <Button variant="text" onClick={() => handleSort("retail_price")}>
                            {priceSort === true ? <ExpandLess /> : <ExpandMore />}
                        </Button> */}
                </TableCell>
                {/* <TableCell>
                    <Typography variant="h5">
                        Unlike?
                    </Typography>
                    </TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>{favRows(paginatedRows, 'f')}</TableBody>
            <TableFooter>
              {paginationControls(filteredFavsList, 'f')}
            </TableFooter>
          </Table>
        </Paper>
      </div>

      {/* Mobile site */}
      <div className="favorites__container-mobile">
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader
              component="div"
              id="nested-list-subheader"
              className="favorites__list-sub-header"
            >
              Customer Favorites
            </ListSubheader>
          }
        >
          {renderSearchList(filteredFavsList, 'm')}
          {paginationControls(filteredFavsList, 'm')}
          {favRows(paginatedRows, 'm')}
        </List>
      </div>
    </div>
  );
};

export default Favorites;
