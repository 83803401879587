import { Accordion, AccordionDetails, AccordionSummary, AppBar, Button, Dialog, DialogContent, DialogTitle, Divider, Drawer, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, List, ListItem, ListItemText, makeStyles, MenuItem, Radio, RadioGroup, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Tooltip, Typography } from "@material-ui/core";
import { ExpandMore, Info } from "@material-ui/icons";
import React, { useContext, useEffect, useState } from "react";
import '../styles/Product.css';
import { requestSampleCheckout, returnSample } from "../../Actions";
import { AlertContext } from "../../Context/AlertContextProvider";

const header = ['Location', 'Color/Option', 'Status'];

export default function CustomerSampleCheckoutDialog(props) {
  const { handleAlertOpen, setMessageType, setMessage } =
        useContext(AlertContext);
  const [sampleColor, setSampleColor] = useState('');
  const [reminderPreference, setReminderPreference] = useState('');

  useEffect(() => {
    if (!props.user) return;

    if (props.user?.contact_by_email == 1 && props.user?.contact_by_phone == 1) {
      setReminderPreference('2');
    } else if (props.user?.contact_by_email == 1) {
      setReminderPreference('0');
    } else if (props.user?.contact_by_phone == 1) {
      setReminderPreference('1');
    } else {
      setReminderPreference('');
    }
  }, [props.user])

  const handleCheckoutSubmit = () => {
      const productSkuId = sampleColor === '' || sampleColor === '0' ? null : sampleColor;
      let contactByEmail = 0;
      let contactByPhone = 0;
  
      switch (reminderPreference) {
        case '0':
          contactByEmail = 1;
          break;
        case '1':
          contactByPhone = 1;
          break;
        case '2':
          contactByEmail = 1;
          contactByPhone = 1;
          break;
      }
  
      requestSampleCheckout(props.product.product_uuid, productSkuId, contactByEmail, contactByPhone).then(() => {
        setMessageType('success');
        setMessage('Checkout request successful!');
        handleAlertOpen();
        props?.checkoutStatus();
      }).catch((err) => {
        setMessageType('error');
        setMessage('Error requesting checkout');
        handleAlertOpen();
      });
    };

  return (
    <Dialog
      open={props.isOpen}
      onClose={() => {
        setSampleColor('');
        props.onClose();
      }}
      maxWidth="xl"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"

    >
      <div id="alert-dialog-title" style={{padding: "15px"}}>
        <Typography variant="h4" style={{display: "inline-block", verticalAlign: "middle", color: "#28C4FC"}}>
          <span className="product__hidden-header">Sample </span>Checkout
        </Typography>
        <div style={{display: "inline-block", verticalAlign: "middle", float: "right"}}>
          <Button
            variant="outlined"
            onClick={() => {
              setSampleColor('');
              props.onClose();
            }}
            className="product__pending-header-button"
            style={{}}
          >
            X
          </Button>
        </div>
      </div>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={4}>
            <List>
              <ListItem>
                <Typography variant="h5">Request Sample</Typography>
              </ListItem>
              <ListItem>
                <Typography variant="subtitle2"> 
                  Please select one of the colors/options in the dropdown below. If all of the colors are on one sample board, please select the "General Product Sample" option.
                </Typography>
              </ListItem>
              <ListItem>
                <FormControl fullWidth>
                  <InputLabel shrink id="sample-selection-label">Sample Selection</InputLabel>
                  <Select
                    labelId="sample-selection-label"
                    id="sample-selection"
                    value={sampleColor}
                    variant="filled"
                    onChange={(e) => setSampleColor(e.target.value)}
                    displayEmpty
                  >
                    <MenuItem dense value="" disabled>Please select one of the sample options</MenuItem>
                    <MenuItem dense value="0">General Product Sample</MenuItem>
                    {props.options?.map((o) => {
                      return (
                        <MenuItem dense key={o.product_sku_uuid} value={o.product_sku_uuid}>
                          {o.color_name}
                          {o.discontinued ? (
                              <Tooltip title="Product discontinued">
                                <Info color="secondary" style={{marginLeft: "5px"}}/>
                              </Tooltip>
                            ) : undefined
                          }
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </ListItem>
              <ListItem>
                <FormControl component="fieldset">
                  <FormLabel component="legend"><Typography>Reminder Preference</Typography></FormLabel>
                  <RadioGroup aria-label="Reminder Preference" name="reminder-preference" row value={reminderPreference} onChange={(e) => setReminderPreference(e.target.value)}>
                    <FormControlLabel value="0" control={<Radio />} disabled={!props.user.email} label="Email" labelPlacement='end'/>
                    <FormControlLabel value="1" control={<Radio />} disabled={!props.user.phone} label="Phone" labelPlacement='end'/>
                    <FormControlLabel value="2" control={<Radio />} disabled={!props.user.email || !props.user.phone} label="Both" labelPlacement='end'/>
                  </RadioGroup>
                </FormControl>
              </ListItem>
              <ListItem>
                <Button
                  variant="contained"
                  style={{
                    margin: '10px 0px',
                    backgroundColor: '#4caf50',
                    color: '#FFFFFF',
                  }}
                  onClick={() => handleCheckoutSubmit()}
                >
                  Request Sample
                </Button>
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} md={6} lg={8} className="product__table-grid">
            <Typography variant="h5" style={{margin: "15px 0px"}}>{props.product?.trade_name} Sample Checkouts</Typography>
            <Typography variant="subtitle2" style={{margin: "15px 0px"}}>If your request is listed as "Pending" below, then is has been submitted for approval. Please see a sales associate to get this approved. If your request has been marked as "Checked Out" then you have automatically been approved and no action is needed.</Typography>
            <TableContainer className="product__table-container">
              <Table aria-label="sample-table" stickyHeader>
                <TableHead>
                  <TableRow>
                    {header.map((column, index) => (
                      <TableCell key={index}>
                        <Typography>
                          {column}
                        </Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.checkoutList?.map((row, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>{row.location_name ?? ''}</TableCell>
                        <TableCell>{row.color_name ?? 'General Product Sample'}</TableCell>
                        <TableCell>{row.checkout_status}</TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>

            <div className="product__list-container">
              {props.checkoutList?.map((row, index) => {
                  return (
                    <Accordion key={row.sample_checkout_id + '-mobile'} style={{margin: "1em auto"}}>
                      <AccordionSummary
                        expandIcon={<ExpandMore style={{color: "#28c4fc"}}/>}
                        aria-controls="sample-content"
                        id="sample-header"
                        style={{backgroundColor: "#142e3e", color: "#28c4fc"}}
                      >
                        <Typography style={{marginBottom: "0px"}}>{row.color_name ?? 'General Product Sample'}</Typography>
                      </AccordionSummary>
                      <AccordionDetails style={{display: "block", backgroundColor: "rgba(0, 0, 0, 0.05)"}}>
                        <Typography style={{marginBottom: "0px"}}><b>{row.color_name ?? 'General Product Sample'}</b></Typography>
                        <Typography>Status: {row.checkout_status}</Typography>
                        <Typography>Location: {row.location_name ?? ''}</Typography>
                      </AccordionDetails>
                    </Accordion>
                  )
                })
              }
            </div>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}