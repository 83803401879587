import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// MUI
import {
  AppBar,
  Toolbar,
  Typography,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  List,
  Button,
  Paper,
  AccordionSummary,
  Accordion,
  AccordionDetails,
} from '@material-ui/core';

// Styles
import '../styles/Checkouts.css';

// Icons
import AssignmentReturnSharpIcon from '@material-ui/icons/AssignmentReturnSharp';
import AssignmentReturnedSharpIcon from '@material-ui/icons/AssignmentReturnedSharp';
import AssignmentTurnedInSharpIcon from '@material-ui/icons/AssignmentTurnedInSharp';
import AssignmentLateSharpIcon from '@material-ui/icons/AssignmentLateSharp';
import AssignmentSharpIcon from '@material-ui/icons/AssignmentSharp';
import Collapse from '@material-ui/core/Collapse';
import StyleIcon from '@material-ui/icons/Style';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import LinkIcon from '@material-ui/icons/Link';
import DateRangeIcon from '@material-ui/icons/DateRange';
import WarningIcon from '@material-ui/icons/Warning';
import CancelIcon from '@material-ui/icons/Cancel';

// Actions
import { getCheckoutList, cancelSampleCheckout } from '../../Actions';
import { sortObjectsByAttribute } from '../../Util/utilsFunctions';

export default function Checkouts(props) {
  const [checkedOutProducts, setCheckedOutProducts] = useState([]);
  const [open, setOpen] = useState(undefined);
  const [refresh, setRefresh] = useState(true);
  const [sortStatus, setSortStatus] = useState(false);
  const [sortStyle, setSortStyle] = useState(false);
  const [sortColor, setSortColor] = useState(false);
  const [sortDaysOut, setSortDaysOut] = useState(false);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('style_name');

  useEffect(() => {
    if (!refresh || !props.isOpen) return;

    getCheckoutList()
      .then((result) => {
        setCheckedOutProducts([...result.data.result]);
      })
      .catch((err) => {
        console.log(err);
      });
    setRefresh(false);
  }, [props.isOpen, refresh]);

  const handleSort = (e) => {
    switch (e) {
      case 'Status':
        if (sortStatus === false) {
          setOrder('desc');
          setOrderBy('status_name');
          setSortStatus(true);
          setSortDaysOut(false);
          setSortStyle(false);
          setSortColor(false);
        } else {
          setSortStatus(false);
          setOrder('asc');
        }
        break;
      case 'Style Name':
        if (sortStyle === false) {
          setOrder('desc');
          setOrderBy('style_name');
          setSortStatus(false);
          setSortDaysOut(false);
          setSortStyle(true);
          setSortColor(false);
        } else {
          setSortStyle(false);
          setOrder('asc');
        }
        break;
      case 'Color Name':
        if (sortColor === false) {
          setOrder('desc');
          setOrderBy('color_name');
          setSortStatus(false);
          setSortDaysOut(false);
          setSortStyle(false);
          setSortColor(true);
        } else {
          setSortColor(false);
          setOrder('asc');
        }
        break;
      case 'Days Out':
        if (sortDaysOut === false) {
          setOrder('desc');
          setOrderBy('days_checked_out');
          setSortStatus(false);
          setSortDaysOut(true);
          setSortStyle(false);
          setSortColor(false);
        } else {
          setSortDaysOut(false);
          setOrder('asc');
        }
        break;
      default:
        setSortStatus(false);
        setSortDaysOut(false);
        setSortStyle(false);
        setSortColor(false);
    }
  };

  const handleClick = (index) => {
    open === index ? setOpen(undefined) : setOpen(index);
  };

  const handleCancelSampleCheckout = (checkoutId) => {
    cancelSampleCheckout(checkoutId)
      .then(() => {
        setRefresh(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  if (!props.isOpen) return <></>;

  if (!checkedOutProducts?.length) {
    return (
      <div>
        <AppBar position="static">
          <Toolbar variant="dense" className="favorites__header">
            <Typography variant="h4" color="inherit" style={{ padding: '15px' }}>
              Sample Checkouts
            </Typography>
          </Toolbar>
        </AppBar>
        <Paper className="link-container">
          {/* <Link to="/signin" className="link"><InputIcon fontSize="large" style={{color:"#4E564E"}}/> */}
          <Typography variant="h6" style={{ padding: '15px' }}>
            You have no samples checked out, scan a product to checkout samples.
          </Typography>
          {/* </Link> */}
        </Paper>
      </div>
    )
  }

  return (
    <div>
      <div className="checkouts__container-full">
        <Paper>
          <AppBar position="static">
            <Toolbar variant="dense" className="favorites__header">
              <Typography
                variant="h4"
                color="inherit"
                style={{ padding: '15px' }}
              >
                Sample Checkouts
              </Typography>
            </Toolbar>
          </AppBar>
        
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography
                    variant="h5"
                    className="checkouts__header-typography"
                  >
                    Style Name
                  </Typography>
                  <Button
                    variant="text"
                    onClick={() => handleSort('Style Name')}
                  >
                    {sortStyle === true ? <ExpandLess /> : <ExpandMore />}
                  </Button>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="h5"
                    className="checkouts__header-typography"
                  >
                    Color
                  </Typography>
                  <Button
                    variant="text"
                    onClick={() => handleSort('Color Name')}
                  >
                    {sortColor === true ? <ExpandLess /> : <ExpandMore />}
                  </Button>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="h5"
                    className="checkouts__header-typography"
                  >
                    Status
                  </Typography>
                  <Button variant="text" onClick={() => handleSort('Status')}>
                    {sortStatus === true ? <ExpandLess /> : <ExpandMore />}
                  </Button>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="h5"
                    className="checkouts__header-typography"
                  >
                    Days Out
                  </Typography>
                  <Button variant="text" onClick={() => handleSort('Days Out')}>
                    {sortDaysOut === true ? <ExpandLess /> : <ExpandMore />}
                  </Button>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="h5"
                    className="checkouts__header-typography"
                  >
                    Actions
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {checkedOutProducts
                .sort((prev, curr) =>
                  sortObjectsByAttribute(prev, curr, order, orderBy)
                )
                .map((product, index) => {
                  return (
                    <TableRow key={'desktop_' + product.sample_checkout_id}>
                      <TableCell className="checkout__table-cell">
                        <Link
                          to={`/product/${product.product_uuid}`}
                          className="checkout__links"
                          target="blank"
                        >
                          {product.status_name === 'Checked Out' ? (
                          <AssignmentReturnSharpIcon
                              style={{ color: '#23B246', marginRight: 10, display: "inline-block", verticalAlign: "bottom" }}
                            />
                          ) : product.status_name === 'Pending' ? (
                            <AssignmentLateSharpIcon
                              style={{ color: '#FF682C', marginRight: 10, display: "inline-block", verticalAlign: "bottom" }}
                            />
                          ) : product.status_name === 'Returned' ? (
                            <AssignmentReturnedSharpIcon
                              style={{ color: '#FF682C', marginRight: 10, display: "inline-block", verticalAlign: "bottom" }}
                            />
                          ) : product.status_name === 'Past Due' ? (
                            <AssignmentLateSharpIcon
                              style={{ color: '#FF0000', marginRight: 10, display: "inline-block", verticalAlign: "bottom" }}
                            />
                          ) : (
                            <AssignmentSharpIcon style={{ marginRight: 10, display: "inline-block", verticalAlign: "bottom" }}/>
                          )}
                          {product.style_name}
                        </Link>
                      </TableCell>
                      <TableCell>{product.color_name ?? 'General Product Sample'}</TableCell>
                      <TableCell>{product.status_name}</TableCell>
                      <TableCell>
                        {product.days_checked_out}
                        {product.days_checked_out > 2 &&
                        product.days_checked_out <= 6 ? (
                          <WarningIcon
                            style={{ paddingLeft: 10, color: '#EED202', display: "inline-block", verticalAlign: "middle" }}
                          />
                        ) : product.days_checked_out >= 7 ? (
                          <WarningIcon
                            style={{ paddingLeft: 10, color: '#D0342C', display: "inline-block", verticalAlign: "middle" }}
                          />
                        ) : (
                          <></>
                        )}
                      </TableCell>
                      <TableCell>
                        {product.status_name === 'Pending' ? (
                          <Button
                            onClick={() => {
                              handleCancelSampleCheckout(
                                product.sample_checkout_id
                              );
                            }}
                          >
                            <CancelIcon style={{ color: '#D0342C', marginRight: "5px" }} /> Cancel Request
                          </Button>
                        ) : undefined }
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </Paper>
      </div>
      {/* Mobile site */}
      <div className="checkouts__container-mobile">
          <AppBar position="static">
            <Toolbar variant="dense" className="favorites__header">
              <Typography
                variant="h4"
                color="inherit"
                style={{ padding: '15px' }}
              >
                Sample Checkouts
              </Typography>
            </Toolbar>
          </AppBar>
          
          {checkedOutProducts
            .sort((prev, curr) =>
              sortObjectsByAttribute(prev, curr, order, orderBy)
            )
            .map((product, index) => {
              return (
                <Accordion key={product.sample_checkout_id + '-mobile'} style={{margin: "1em auto"}}>
                  <AccordionSummary
                    expandIcon={<ExpandMore style={{color: "#28c4fc"}}/>}
                    aria-controls="sample-content"
                    id="sample-header"
                    style={{backgroundColor: "#142e3e", color: "#28c4fc"}}
                  >
                    <Typography>{product.style_name}</Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{display: "block"}}>
                    <Typography style={{margin: "0em 0em .5em"}}>
                      <Link
                        to={`/product/${product.product_uuid}`}
                        className="checkout__links"
                        target="blank"
                      >
                        {product.status_name === 'Checked Out' ? (
                          <AssignmentReturnSharpIcon
                            style={{ color: '#23B246', marginRight: 10, display: "inline-block", verticalAlign: "bottom" }}
                          />
                        ) : product.status_name === 'Pending' ? (
                          <AssignmentLateSharpIcon
                            style={{ color: '#FF682C', marginRight: 10, display: "inline-block", verticalAlign: "bottom" }}
                          />
                        ) : product.status_name === 'Returned' ? (
                          <AssignmentReturnedSharpIcon
                            style={{ color: '#FF682C', marginRight: 10, display: "inline-block", verticalAlign: "bottom" }}
                          />
                        ) : product.status_name === 'Past Due' ? (
                          <AssignmentLateSharpIcon
                            style={{ color: '#FF0000', marginRight: 10, display: "inline-block", verticalAlign: "bottom" }}
                          />
                        ) : (
                          <AssignmentSharpIcon style={{ marginRight: 10, display: "inline-block", verticalAlign: "bottom" }}/>
                        )}
                        {product.style_name}
                      </Link>
                    </Typography>
                    <Typography style={{margin: "0em 0em .5em"}}>Color: {product.color_name ?? 'General Product Sample'}</Typography>
                    <Typography style={{margin: "0em 0em .5em"}}>Status: {product.status_name}</Typography>
                    <Typography>
                      Days In Status:{' '}
                      {product.days_checked_out}
                      {product.days_checked_out > 2 &&
                      product.days_checked_out <= 6 ? (
                        <WarningIcon
                          style={{ paddingLeft: 10, color: '#EED202', display: "inline-block", verticalAlign: "middle" }}
                        />
                      ) : product.days_checked_out >= 7 ? (
                        <WarningIcon
                          style={{ paddingLeft: 10, color: '#D0342C', display: "inline-block", verticalAlign: "middle" }}
                        />
                      ) : undefined }
                    </Typography>
                    {product.status_name === 'Pending' ? (
                      <Button
                        style={{display: "block", margin: ".5em 0em 0em"}}
                        onClick={() => {
                          handleCancelSampleCheckout(
                            product.sample_checkout_id
                          );
                        }}
                      >
                        <CancelIcon style={{ color: '#D0342C', marginRight: "5px", display: "inline-block", verticalAlign: "middle" }} />Cancel Request
                      </Button>
                    ) : undefined }
                  </AccordionDetails>
                </Accordion>
              );
            })
          }     
      </div>
    </div>
  );
}
