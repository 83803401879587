import React, { useRef, useEffect, useState } from 'react';

// Icons
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';

// Dependencies
import Signin from '../LandingPage/Signin';
import ReCAPTCHA from 'react-google-recaptcha';

// Context
import { CompanyLogoContext } from '../../Context/CompanyLogoContextProvider';

// MUI
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

//style
import '../styles/Register.css';

//action
import {
  userNameExists,
  getCustomerCreateAccountStatus,
  customerRegisterLocalUser,
} from '../../Actions';

const USER_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;
const PWD_REGEX = /[a-zA-Z0-9]$/;
const EMAIL_REGEX = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
const PHONE_REGEX =
  /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;

const Register = () => {
  const { companyLogo } = React.useContext(CompanyLogoContext);

  const userRef = useRef();
  const errRef = useRef();

  const [name, setName] = useState('');
  const [NameFocus, setNameFocus] = useState(false);

  const [email, setEmail] = useState('');
  const [validEmail, setValidEmail] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);

  const [user, setUser] = useState('');
  const [validUserName, setValidUserName] = useState(false);
  const [userFocus, setUserFocus] = useState(false);
  const [userExists, setUserExists] = useState(false);

  const [phone, setPhone] = useState('');
  const [phoneFocus, setPhoneFocus] = useState(false);
  const [validPhone, setValidPhone] = useState(false);

  const [pwd, setPwd] = useState('');
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);
  const [showPass, setShowPass] = useState('password');

  const [matchPwd, setMatchPwd] = useState('');
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);

  const [errMsg, setErrMsg] = useState('');
  const [success, setSuccess] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [regMessage, setRegMessage] = useState('');

  const [recaptchaKey, setRecaptchaKey] = useState();
  const [newUserAccountRegStatus, setNewUserAccountRegStatus] = useState(0);
  const [value, setValue] = useState(0);
  const [reminderPreference, setReminderPreference] = useState('');

  useEffect(() => {
    getCustomerCreateAccountStatus().then((result) => {
      setNewUserAccountRegStatus(Number(result.data.data[0].app_value));
      if (Number(result.data.data[0].app_value) === 1) {
        userRef.current.focus();
      }
    });
  }, [value]);

  useEffect(() => {
    setValidPhone(PHONE_REGEX.test(phone));
  }, [phone]);

  useEffect(() => {
    setValidEmail(EMAIL_REGEX.test(email));
  }, [email]);

  useEffect(() => {
    userNameExists(user).then((result) => {
      result.data.isUserValid === true
        ? setUserExists(false)
        : setUserExists(true);
    });
    setValidUserName(USER_REGEX.test(user));
  }, [user]);

  useEffect(() => {
    setValidPwd(PWD_REGEX.test(pwd));
    setValidMatch(pwd === matchPwd);
  }, [pwd, matchPwd]);

  useEffect(() => {
    setErrMsg('');
  }, [user, pwd, matchPwd]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const v1 = USER_REGEX.test(user);
    const v2 = PWD_REGEX.test(pwd);
    if (!v1 || !v2) {
      setErrMsg('Invalid Entry');
      return;
    } else {
      let contactByEmail = 0;
      let contactByPhone = 0;

      switch (reminderPreference) {
        case '0':
          contactByEmail = 1;
          break;
        case '1':
          contactByPhone = 1;
          break;
        case '2':
          contactByEmail = 1;
          contactByPhone = 1;
          break;
      }

      customerRegisterLocalUser(name, email, user, phone, pwd, recaptchaKey, contactByEmail, contactByPhone)
        .then((result) => {
          result.data.message === 'user created'
            ? setRegMessage(
                'Registration complete, check your email to activate your account'
              )
            : setRegMessage(
                'Something went wrong, please try again or contact support at' +
                (
                  <a href="mailto:support@showroompricing.com">
                    support@showroompricing.com
                  </a>
                )
              );
          setAlertOpen(true);
        })
        .catch((err) => {
          console.log(err);
        });

      setRecaptchaKey(undefined);
    }
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
    window.location.href = '/signin';
  };

  const alert = () => {
    return (
      <Dialog
        open={alertOpen}
        onClose={handleAlertClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">New account</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {regMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleAlertClose} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const handleClickShowPassword = () => {
    showPass === 'password' ? setShowPass('text') : setShowPass('password');
  };

  return newUserAccountRegStatus === 1 ? (
    <div className="container">
      {alert()}
      {success ? (
        <Signin />
      ) : (
        <section className="register__section">
          {/* <div className="signin__container-header">
         				 <img className="company-logo" src={companyLogo} alt="logo"></img>
        			</div> */}
          <p
            ref={errRef}
            className={errMsg ? 'errmsg' : 'hide'}
            aria-live="assertive"
          >
            {errMsg}
          </p>
          <div className="reg__container-header">
            <Typography
              variant="h2"
              align="center"
              className="reg__header-text"
            >
              Registration
            </Typography>
          </div>
          <form className="register__form" onSubmit={handleSubmit}>
            <label className="register__label" htmlFor="confirm_pwd">
              Name:
            </label>
            <span className="register__text-field_span">
              <OutlinedInput
                className="register__text-field"
                type="text"
                id="confirm_name"
                onChange={(e) => setName(e.target.value)}
                value={name}
                required
                aria-invalid={validMatch ? 'false' : 'true'}
                aria-describedby="confirmnote"
                onFocus={() => setNameFocus(true)}
                onBlur={() => setNameFocus(false)}
                endAdornment={
                  <InputAdornment position="end">
                    {name ? (
                      <CheckIcon className="valid" />
                    ) : (
                      <ClearIcon className="invalid" />
                    )}
                  </InputAdornment>
                }
              />
            </span>
            <label className="register__label" htmlFor="confirm_email">
              Email Address:
            </label>
            <span className="register__text-field_span">
              <OutlinedInput
                className="register__text-field"
                type="email"
                id="confirm_email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                required
                aria-invalid={validEmail ? 'false' : 'true'}
                aria-describedby="confirmnote"
                onFocus={() => setEmailFocus(true)}
                onBlur={() => setEmailFocus(false)}
                endAdornment={
                  <InputAdornment position="end">
                    {validEmail ? (
                      <CheckIcon className="valid" />
                    ) : (
                      <ClearIcon className="invalid" />
                    )}
                  </InputAdornment>
                }
              />
            </span>
            <p
              id="uidnote"
              className={
                emailFocus && email && !validEmail ? 'instructions' : 'hide'
              }
            >
              Please enter a valid email address
            </p>

            <label className="register__label" htmlFor="confirm_pwd">
              Phone:
            </label>
            <span className="register__text-field_span">
              <OutlinedInput
                className="register__text-field"
                type="text"
                onChange={(e) => setPhone(e.target.value)}
                value={phone}
                required
                aria-invalid={validPhone ? 'false' : 'true'}
                aria-describedby="confirmnote"
                onFocus={() => setPhoneFocus(true)}
                onBlur={() => setPhoneFocus(false)}
                endAdornment={
                  <InputAdornment position="end">
                    {validPhone ? (
                      <CheckIcon className="valid" />
                    ) : (
                      <ClearIcon className="invalid" />
                    )}
                  </InputAdornment>
                }
              />
            </span>
            <p
              id="pwdnote"
              className={phoneFocus && !validPhone ? 'instructions' : 'hide'}
            >
              Please use one of the following formats:
              <br />
              1234567890 123-456-7890 <br />
              (123) 456-7890 <br />
              123 456 7890 <br />
              123.456.7890 <br />
              +91 (123) 456-7890 <br />
              <br />
            </p>

              <label className="register__label">Contact Preference:</label>
              <RadioGroup aria-label="Reminder Preference" className="register__text-field_span" name="reminder-preference" row value={reminderPreference} onChange={(e) => setReminderPreference(e.target.value)}>
                <FormControlLabel value="0" control={<Radio />} disabled={!email} label="Email" labelPlacement='end'/>
                <FormControlLabel value="1" control={<Radio />} disabled={!phone} label="Phone" labelPlacement='end'/>
                <FormControlLabel value="2" control={<Radio />} disabled={!email || !phone} label="Both" labelPlacement='end'/>
              </RadioGroup>
            
            <label className="register__label" htmlFor="username">
              Username:
            </label>
            <span className="register__text-field_span">
              <OutlinedInput
                className="register__text-field"
                type="text"
                id="username"
                ref={userRef}
                autoComplete="off"
                onChange={(e) => setUser(e.target.value)}
                value={user}
                required
                aria-invalid={validUserName ? 'false' : 'true'}
                aria-describedby="uidnote"
                onFocus={() => setUserFocus(true)}
                onBlur={() => setUserFocus(false)}
                endAdornment={
                  <InputAdornment position="end">
                    {validUserName && userExists === false ? (
                      <CheckIcon className="valid" />
                    ) : (
                      <ClearIcon className="invalid" />
                    )}
                  </InputAdornment>
                }
              />
            </span>
            <p
              id="uidnote"
              className={
                userFocus && user && !validUserName ? 'instructions' : 'hide'
              }
            >
              4 to 24 characters.
              <br />
              Must begin with a letter.
              <br />
              Letters, numbers, underscores, hyphens allowed.
            </p>
            <p id="uidnote" className={userExists ? 'user-exists' : 'hide'}>
              Username already exists.
            </p>

            <label className="register__label" htmlFor="password">
              Password:
            </label>
            <span className="register__text-field_span">
              <OutlinedInput
                className="register__text-field"
                variant="outlined"
                type={showPass}
                id="password"
                onChange={(e) => setPwd(e.target.value)}
                value={pwd}
                required
                aria-invalid={validPwd ? 'false' : 'true'}
                aria-describedby="pwdnote"
                onFocus={() => setPwdFocus(true)}
                onBlur={() => setPwdFocus(false)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={handleClickShowPassword}>
                      {showPass === 'text' ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                    {validPwd ? (
                      <CheckIcon className="valid" />
                    ) : (
                      <ClearIcon className="invalid" />
                    )}
                  </InputAdornment>
                }
              />
            </span>
            {/* <p
							id="pwdnote"
							className={pwdFocus && !validPwd ? 'instructions' : 'hide'}
						>
							8 to 24 characters.
							<br />
							Must include uppercase and lowercase letters, a number and a
							special character.
							<br />
							Allowed special characters:{' '}
							<span aria-label="exclamation mark">!</span>{' '}
							<span aria-label="at symbol">@</span>{' '}
							<span aria-label="hashtag">#</span>{' '}
							<span aria-label="dollar sign">$</span>{' '}
							<span aria-label="percent">%</span>
						</p> */}

            {/* <label 
                            className="register__label"
                            htmlFor="confirm_pwd">
							Confirm Password:
						</label> */}
            {/* <span className="register__text-field_span">
						<OutlinedInput
                            className="register__text-field"
							type={showPass}
							id="confirm_pwd"
							onChange={(e) => setMatchPwd(e.target.value)}
							value={matchPwd}
							required
							aria-invalid={validMatch ? 'false' : 'true'}
							aria-describedby="confirmnote"
							onFocus={() => setMatchFocus(true)}
							onBlur={() => setMatchFocus(false)}
							endAdornment={
								<InputAdornment position="end">
								  <IconButton
									onClick={handleClickShowPassword}
								  >
									{showPass === "text" ? <Visibility /> : <VisibilityOff />}								
								  </IconButton>
								  {validMatch && matchPwd ? <CheckIcon className="valid"/> : <ClearIcon className="invalid"/>}
								</InputAdornment>
							  }
						/>
						</span>
						<p
							id="confirmnote"
							className={
								matchFocus && !validMatch ? 'instructions' : 'hide'
							}
						>
							Must match the first password input field.
						</p> */}
            <button
              className="register__signup-button"
              disabled={
                !validUserName ||
                !validPwd ||
                !recaptchaKey ||
                !name ||
                !validEmail ||
                !reminderPreference ||
                userExists === true
                  ? true
                  : false
              }
            >
              Sign Up
            </button>
          </form>
          <p className="register__text">
            Already registered?
            <br />
            <span className="line">
              <a className="register__signin-link" href="/signin">
                Sign In
              </a>
            </span>
          </p>
          <ReCAPTCHA
            onChange={(e) => setRecaptchaKey(e)}
            className="register__captcha"
            sitekey={'6Lf_H9oiAAAAAG34G9GGtyXkuAMiep3_qhZSf-Nf'}
            // size="invisible"
            // ref={reCaptchaRef}
          />
          {/* <p className="register__captcha-branding">
                        This site is protected by reCAPTCHA and the Google
                        <a href="https://policies.google.com/privacy">Privacy Policy</a> and
                        <a href="https://policies.google.com/terms">Terms of Service</a> apply.
                    </p> */}
        </section>
      )}
    </div>
  ) : (
    <div></div>
  );
};

export default Register;
