import React, { useContext, useState, useEffect, useRef } from 'react';

// MUI
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import Input from '@material-ui/core/Input';
import Tooltip from '@material-ui/core/Tooltip';
import NativeSelect from '@material-ui/core/NativeSelect';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import OutlinedInput from '@material-ui/core/OutlinedInput';
// import Alert from "@material-ui/lab/Alert";

// actions
import {
  createUser,
  registerLocalUser,
  createRole,
  getUserNames,
} from '../../../Actions';

// Icons
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';

// styles
import '../../styles/Admin.css';

// dependents
import { AdminContext } from '../../../Context/AdminContextProvider';
import { AlertContext } from '../../../Context/AlertContextProvider';
import { Checkbox, FormLabel } from '@material-ui/core';

// component
const CreateUser = (props) => {
  const [open, setOpen] = useState(props.open);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [access, setAccess] = useState('');
  const [accessCode, setAccessCode] = useState('');
  const [isRSA, setIsRSA] = useState(0);
  const [selectedValue, setSelectedValue] = useState('local');
  const [role, setRole] = useState('');
  const [roleDescription, setRoleDescription] = useState('');
  const [roleList, setRoleList] = useState([]);
  const [roleId, setRoleId] = useState();
  const [roleName, setRoleName] = useState('');
  const [locationId, setLocationId] = useState();
  const { setUpdate, accesstypes, locations } = useContext(AdminContext);
  const { handleAlertOpen, setMessageType, setMessage } =
    useContext(AlertContext);
  const USER_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;
  const PWD_REGEX = /[a-zA-Z0-9]$/;
  const EMAIL_REGEX = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);
  const [showPass, setShowPass] = useState('password');
  const [matchPwd, setMatchPwd] = useState('');
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);

  const [user, setUser] = useState('');
  const [validUserName, setValidUserName] = useState(false);
  const [userFocus, setUserFocus] = useState(false);
  const [userExists, setUserExists] = useState(false);
  const [reminderPreference, setReminderPreference] = useState('')
  const userRef = useRef();

  useEffect(() => {
    setValidPwd(PWD_REGEX.test(password));
    setValidMatch(password === matchPwd);
  }, [password, matchPwd]);

  useEffect(() => {
    let isMounted = true;
    getUserNames().then((result) => {
      if (isMounted)
        result.data.data.some(
          (item) => username.toLowerCase().trim() === item.username
        )
          ? setUserExists(true)
          : setUserExists(false);
    });
    setValidUserName(USER_REGEX.test(username));

    return () => {
      isMounted = false;
    };
  }, [username]);

  useEffect(() => {
    accessCode === 'c' ? setIsRSA(0) : setIsRSA(1);
  }, [accessCode]);

  useEffect(() => {
    setRoleList(props.roles);
  }, [props.roles]);

  const handleOpen = () => {
    setOpen(true);
  };

  const resetvars = () => {
    setName('');
    setEmail('');
    setPhone('');
    setPassword('');
    setUsername('');
    setAccessCode('');
    setAccess('');
    setRole('');
    setRoleDescription('');
  };

  const handleClose = () => {
    setOpen(false);
    resetvars();
  };

  const handleName = (name) => {
    name.trim();
    setName(name);
  };

  const handleEmail = (email) => {
    email.trim();
    setEmail(email);
  };

  const handlePhone = (phone) => {
    phone.trim();
    setPhone(phone);
  };

  const handleUserName = (username) => {
    username.trim();
    setUsername(username);
  };

  const handlePasswordChange = (password) => {
    password.trim();
    setPassword(password);
  };

  const handleRadioChange = (e) => {
    setSelectedValue(e.target.value);
    resetvars();
  };

  const toggleRsaAssignment = (v) => {
    setIsRSA(v ? 0 : 1);
  };

  const handleRoleSelectChange = (e) => {
    if (e.target.value === 'Create Role') {
      setRoleName('Create Role');
    } else {
      const roleObject = roleList.find((r) => r.role_name === e.target.value);
      setRoleId(roleObject.role_id);
      setRoleName(roleObject.role_name);
    }
  };

  const handleLocationSelectChange = (e) => {
    const locationObject = locations.find(
      (l) => l.location_name === e.target.value
    );
    setLocationId(locationObject.location_id);
  };

  const handleSubmit = (type) => {
    if (type === 'google') {
      createUser(username, accessCode)
        .then((res) => {
          const message = res.data.message;
          setMessageType('success');
          setMessage(message);
          setUpdate(true);
          handleAlertOpen();
        })
        .catch((err) => {
          const message = 'Failed to create user!';
          setMessageType('error');
          setMessage(message);
        });
    }
    if (type === 'local') {
      let contactByEmail = 0;
      let contactByPhone = 0;

      switch (reminderPreference) {
        case '0':
          contactByEmail = 1;
          break;
        case '1':
          contactByPhone = 1;
          break;
        case '2':
          contactByEmail = 1;
          contactByPhone = 1;
          break;
      }

      if (role === '') {
        registerLocalUser(
          name,
          email,
          username.trim(),
          phone,
          password,
          accessCode,
          roleId,
          locationId,
          isRSA,
          contactByEmail,
          contactByPhone 
        )
          .then((res) => {
            const message = res.data.message;
            setMessageType('success');
            setMessage(message);
            setUpdate(true);
            handleAlertOpen();
          })
          .catch((err) => {
            const message = 'Failed to create user!';
            setMessageType('error');
            setMessage(message);
            handleAlertOpen();
          });
      } else {
        createRole(role, roleDescription, roleName, roleId)
          .then((res) => {
            registerLocalUser(
              name,
              email,
              username.trim(),
              phone,
              password,
              accessCode,
              roleId,
              locationId,
              isRSA,
              contactByEmail,
              contactByPhone
            )
              .then((res) => {
                const message = res.data.message;
                setMessageType('success');
                setMessage(message);
                setUpdate(true);
                handleAlertOpen();
              })
              .catch((err) => {
                const message = 'Failed to create user!';
                setMessageType('error');
                setMessage(message);
                handleAlertOpen();
              });
            const message = res.data.message;
            setMessageType('success');
            setMessage(message);
            setUpdate(true);
            handleAlertOpen();
          })
          .catch((err) => {
            const message = 'User Role Already Exists!';
            setMessageType('error');
            setMessage(message);
            handleAlertOpen();
          });
      }
    }
  };

  const handleAccessChange = (e) => {
    const accessType = e.target.value;
    setAccess(accessType);
    const tempAccess = accesstypes.filter(
      (access) => access.access_type === accessType
    )[0];
    setAccessCode(tempAccess.access_type_cd);
  };

  const canCreateUser = () => {
    if (selectedValue === 'google') {
      return false;
    }
    if (
      username.length &&
      password.length &&
      email.length &&
      name.length &&
      phone.length &&
      accessCode.length
    ) {
      return false;
    }
    return true;
  };

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  const generatePassword = (e) => {
    const randomPassword = Math.random().toString(36).slice(2);
    setPassword(randomPassword);
  };

  const handleClickShowPassword = () => {
    showPass === 'password' ? setShowPass('text') : setShowPass('password');
  };

  const renderAccessNotes = () => {
    return accesstypes.map((access) => {
      return (
        <small key={access.access_type}>
          ** <strong>{access.access_type}:</strong> {access.access_description}{' '}
          <br />
        </small>
      );
    });
  };

  const renderAccessOptions = () => {
    return (
      <TextField
        required
        fullWidth
        className="admin__edit-user-dropdowns"
        defaultValue={access}
        onChange={handleAccessChange}
        select
        label="Access Type"
        SelectProps={{
          native: true,
        }}
        variant="outlined"
      >
        <option></option>
        {accesstypes.map((access) => {
          return (
            <option key={access.access_type} value={access.access_type}>
              {access.access_type}
            </option>
          );
        })}
      </TextField>
    );
  };

  const renderSelectLabel = () => {
    return access === 'Customer' ? <div>Select Role:</div> : <div></div>;
  };

  const renderRoleSelect = () => {
    return access === 'Customer' ? (
      <TextField
        required
        fullWidth
        className="admin__edit-user-dropdowns"
        defaultValue={access}
        onChange={handleRoleSelectChange}
        select
        label="Role"
        SelectProps={{
          native: true,
        }}
        variant="outlined"
      >
        <option></option>
        {roleList.map((r, index) => {
          return (
            <option
              key={index}
              value={r.role_name}
              description={r.role_description}
            >
              {r.role_name}
            </option>
          );
        })}
      </TextField>
    ) : (
      <div></div>
    );
  };

  const renderLocationLabel = () => {
    return access === 'Customer' ? <div></div> : <div>Select Location:</div>;
  };

  const renderLocationSelect = (locations) => {
    return access === 'Customer' ? (
      <div></div>
    ) : (
      <TextField
        required
        fullWidth
        className="admin__edit-user-dropdowns"
        onChange={handleLocationSelectChange}
        defaultValue={'Select Location'}
        select
        label="Location"
        SelectProps={{
          native: true,
        }}
        variant="outlined"
      >
        <option></option>
        {locations.map((l, index) => {
          return (
            <option key={index} value={l.location_name} id={l.location_id}>
              {l.location_name} - {l.location_description}{' '}
            </option>
          );
        })}
      </TextField>
    );
  };

  const renderForm = (type) => {
    return type === 'google' ? (
      <form>
        <table className="admin__create-user-google-text">
          <tbody>
            <tr>
              {/* <td>User Name:</td> */}
              <td>
                <TextField
                  fullWidth
                  style={{ marginBottom: 20 }}
                  SelectProps={{
                    native: true,
                  }}
                  variant="outlined"
                  label="User Name"
                  required
                  type="text"
                  name="username"
                  value={username}
                  onChange={(e) => handleUserName(e.target.value)}
                />
              </td>
            </tr>
            <tr>
              {/* <td>Access Level:</td> */}
              <td>{renderAccessOptions()}</td>
            </tr>
            <tr className="admin__create-user-table-row">
              <td className="admin__create-user-table-d">
                <FormControl component="fieldset" fullWidth>
                  <FormLabel component="legend"><Typography>Reminder Preference</Typography></FormLabel>
                  <RadioGroup aria-label="Reminder Preference" name="reminder-preference" row value={reminderPreference} onChange={(e) => setReminderPreference(e.target.value)}>
                    <FormControlLabel value="0" control={<Radio />} disabled={!email} label="Email" labelPlacement='end'/>
                    <FormControlLabel value="1" control={<Radio />} disabled={!phone} label="Phone" labelPlacement='end'/>
                    <FormControlLabel value="2" control={<Radio />} disabled={!email || !phone} label="Both" labelPlacement='end'/>
                  </RadioGroup>
                </FormControl>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    ) : (
      <form style={{ display: 'flex', justifyContent: 'center' }}>
        <table className="admin__create-user-google-text">
          <tbody className="admin__create-user-table-body">
            <tr className="admin__create-user-table-row">
              {/* <td className="admin__create-user-table-divider">Name:</td> */}
              <td className="admin__create-user-table-d">
                <TextField
                  SelectProps={{
                    native: true,
                  }}
                  variant="outlined"
                  fullWidth
                  label="Name"
                  required
                  type="text"
                  name="name"
                  value={name}
                  onChange={(e) => handleName(e.target.value)}
                />
              </td>
            </tr>
            <tr className="admin__create-user-table-row">
              {/* <td className="admin__create-user-table-divider">Email:</td> */}
              <td className="admin__create-user-table-d">
                <TextField
                  SelectProps={{
                    native: true,
                  }}
                  variant="outlined"
                  fullWidth
                  label="Email"
                  required
                  type="text"
                  name="email"
                  value={email}
                  onChange={(e) => handleEmail(e.target.value)}
                />
              </td>
            </tr>
            <tr className="admin__create-user-table-row">
              {/* <td className="admin__create-user-table-divider">Email:</td> */}
              <td className="admin__create-user-table-d">
                <TextField
                  SelectProps={{
                    native: true,
                  }}
                  variant="outlined"
                  fullWidth
                  label="phone"
                  required
                  type="text"
                  name="phone"
                  value={phone}
                  onChange={(e) => handlePhone(e.target.value)}
                />
              </td>
            </tr>
            <tr className="admin__create-user-table-row">
              {/* <td className="admin__create-user-table-divider">User Name:</td> */}
              <td className="admin__create-user-table-d">
                <FormControl variant="outlined" fullWidth>
                  <InputLabel htmlFor="outlined-adornment-password">
                    Username *
                  </InputLabel>
                  <OutlinedInput
                    fullWidth
                    type="text"
                    label="Username"
                    ref={userRef}
                    autoComplete="off"
                    onChange={(e) => handleUserName(e.target.value)}
                    value={username}
                    required
                    aria-invalid={validUserName ? 'false' : 'true'}
                    aria-describedby="uidnote"
                    onFocus={() => setUserFocus(true)}
                    onBlur={() => setUserFocus(false)}
                    endAdornment={
                      <InputAdornment position="end">
                        {validUserName && userExists === false ? (
                          <CheckIcon className="valid" />
                        ) : (
                          <ClearIcon className="invalid" />
                        )}
                      </InputAdornment>
                    }
                  />
                </FormControl>
                <p
                  id="uidnote"
                  className={
                    userFocus && username && !validUserName
                      ? 'instructions'
                      : 'hide'
                  }
                >
                  4 to 24 characters.
                  <br />
                  Must begin with a letter.
                  <br />
                  Letters, numbers, underscores, hyphens allowed.
                </p>
                <p id="uidnote" className={userExists ? 'user-exists' : 'hide'}>
                  Username already exists.
                </p>
                {/* <TextField
                  SelectProps={{
                    native: true,
                  }}
                  variant="outlined"
                  fullWidth
                  label="User Name"
                  required
                  type="text"
                  name="username"
                  value={username}
                  onChange={(e) => handleUserName(e.target.value)}
                /> */}
              </td>
            </tr>
            <tr className="admin__create-user-table-row">
              {/* <td className="admin__create-user-table-divider">Password:</td> */}
              <td className="admin__create-user-table-d">
                <FormControl variant="outlined" fullWidth>
                  <InputLabel htmlFor="outlined-adornment-password">
                    Password *
                  </InputLabel>
                  <OutlinedInput
                    fullWidth
                    type={showPass}
                    value={password}
                    onChange={(e) => handlePasswordChange(e.target.value)}
                    variant="outlined"
                    id="password"
                    required
                    aria-invalid={validPwd ? 'false' : 'true'}
                    aria-describedby="pwdnote"
                    onFocus={() => setPwdFocus(true)}
                    onBlur={() => setPwdFocus(false)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton onClick={handleClickShowPassword}>
                          {showPass === 'text' ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {/* <p
							id="pwdnote"
							className={pwdFocus && !validPwd ? 'instructions' : 'hide'}
						>
							8 to 24 characters.
							<br />
							Must include uppercase and lowercase letters, a number and a
							special character.
							<br />
							Allowed special characters:{' '}
							<span aria-label="exclamation mark">!</span>{' '}
							<span aria-label="at symbol">@</span>{' '}
							<span aria-label="hashtag">#</span>{' '}
							<span aria-label="dollar sign">$</span>{' '}
							<span aria-label="percent">%</span>
						</p> */}
                </FormControl>
              </td>
            </tr>
            {/* <tr>
                <td className="admin__create-user-table-d">
                <span className="register__text-field_span">
                <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="outlined-adornment-password">Password *</InputLabel>
                  <OutlinedInput
                    fullWidth
                    type={showPass}
                    id="confirm_pwd"
                    onChange={(e) => setMatchPwd(e.target.value)}
                    value={matchPwd}
                    required
                    aria-invalid={validMatch ? 'false' : 'true'}
                    aria-describedby="confirmnote"
                    onFocus={() => setMatchFocus(true)}
                    onBlur={() => setMatchFocus(false)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                        onClick={handleClickShowPassword}
                        >
                        {showPass === "text" ? <Visibility /> : <VisibilityOff />}								
                        </IconButton>
                      </InputAdornment>
                      }
                  />
                  </FormControl>
                  </span>
                  <p
                    id="confirmnote"
                    className={
                      matchFocus && !validMatch ? 'instructions' : 'hide'
                    }
                  >
                    Must match the first password input field.
                  </p>
                </td>
              </tr> */}

            <tr className="admin__create-user-table-row">
              {/* <td className="admin__create-user-table-divider">Access Level:</td> */}
              <td className="admin__create-user-table-d">
                {renderAccessOptions()}
              </td>
            </tr>
            {accessCode && accessCode !== 'c' && (
              <tr className="admin__create-user-table-row">
                <td className="admin__create-user-table-d">
                  <FormControlLabel
                    control={
                      <Checkbox
                        name={'checkedRSA'}
                        checked={!isRSA ? false : true}
                        onChange={() => toggleRsaAssignment(isRSA)}
                      />
                    }
                    label="Retail Sales Associate (RSA)"
                  />
                </td>
              </tr>
            )}
            <tr className="admin__create-user-table-row">
              {/* <td className="admin__create-user-table-divider">{renderLocationLabel()}</td> */}
              <td className="admin__create-user-table-d">
                {renderLocationSelect(locations)}
              </td>
            </tr>
            <tr className="admin__create-user-table-row">
              {/* <td className="admin__create-user-table-divider">{renderSelectLabel()}</td> */}
              <td className="admin__create-user-table-d">
                {renderRoleSelect()}
              </td>
            </tr>
            <tr className="admin__create-user-table-row">
              <td className="admin__create-user-table-d">
                <FormControl component="fieldset" fullWidth>
                  <FormLabel component="legend"><Typography>Reminder Preference</Typography></FormLabel>
                  <RadioGroup aria-label="Reminder Preference" name="reminder-preference" row value={reminderPreference} onChange={(e) => setReminderPreference(e.target.value)}>
                    <FormControlLabel value="0" control={<Radio />} disabled={!email} label="Email" labelPlacement='end'/>
                    <FormControlLabel value="1" control={<Radio />} disabled={!phone} label="Phone" labelPlacement='end'/>
                    <FormControlLabel value="2" control={<Radio />} disabled={!email || !phone} label="Both" labelPlacement='end'/>
                  </RadioGroup>
                </FormControl>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    );
  };

  return (
    <div>
      <Button
        onClick={handleOpen}
        className="user__pw-change-button"
        variant="contained"
      >
        Add User
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle
          className="Admin__create-user-header"
          id="alert-dialog-title"
        >
          Create User
          <Button
            variant="outlined"
            onClick={handleClose}
            className="admin__user-header-button"
          >
            x
          </Button>
        </DialogTitle>
        <DialogContent dividers>
          <FormControl component="fieldset">
            <Typography className="create-user__subtitle" variant="subtitle2">
              Select user creation method
            </Typography>
            <RadioGroup
              className="create-user__type"
              aria-label="gender"
              name="gender1"
              value={selectedValue}
              onChange={handleRadioChange}
            >
              {/* <FormControlLabel
                value="google"
                control={<Radio className="create-user__radio" />}
                label="Google"
              /> */}
              <FormControlLabel
                value="local"
                control={<Radio className="create-user__radio" />}
                label="Local"
              />
            </RadioGroup>
          </FormControl>
          {renderForm(selectedValue)}
          <p>{renderAccessNotes()}</p>
        </DialogContent>
        <DialogActions style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            onClick={handleClose}
            autoFocus
            variant="contained"
            className="user__pw-change-button"
          >
            Cancel
          </Button>
          <Button
            onClick={(e) => handleSubmit(selectedValue)}
            className="Admin__create-user"
            variant="contained"
            autoFocus
            disabled={canCreateUser()}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CreateUser;
