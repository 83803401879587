import React, { useContext, useEffect, useState } from "react";
import { approveCheckout, cancelSampleCheckout, checkin, getCustomerUserInfo, guestCheckout, requestSampleCheckout, returnSample } from "../../Actions";
import { Autocomplete } from '@material-ui/lab';
import '../styles/Product.css';
import { Accordion, AccordionDetails, AccordionSummary, AppBar, Button, Chip, Collapse, Dialog, DialogContent, Divider, Drawer, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, List, ListItem, ListItemIcon, ListItemText, makeStyles, MenuItem, Radio, RadioGroup, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Toolbar, Tooltip, Typography } from "@material-ui/core";
import { Email, ExpandLess, ExpandMore, Info, LibraryAddCheck, Person, Phone, Style } from "@material-ui/icons";
import { AlertContext } from "../../Context/AlertContextProvider";

const header = ['Name', 'Location', 'Color/Option', 'Status', 'Days', 'Approve?'];
const drawerWidth = 320;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: 675,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    position: 'absolute',
    backgroundColor: '#142E3E',
    alignItems: 'center',
    width: '100%',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    position: 'absolute',
  },
  drawerContainer: {
    overflow: 'auto',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  headerText: {
    color: '#28C4FC !important',
    marginLeft: 100,
  },
  button: {
    backgroundColor: '#EEEEEE',
    color: '#ff682c',
    margin: 10,
  },
  tableHeader: {
    fontSize: 30,
    '@media (max-width: 1090px)': {
      fontSize: 15,
    },
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '97%',
  },
  closeButton: {
    marginRight: 15,
  },
  autoComplete: {
    width: 170,
  },
}));

export default function SampleCheckoutDialog(props) {
  const { handleAlertOpen, setMessageType, setMessage } =
      useContext(AlertContext);
  const classes = useStyles();
  const [checkoutName, setCheckoutName] = useState({});
  const [checkoutEmail, setCheckoutEmail] = useState({});
  const [checkoutPhone, setCheckoutPhone] = useState({});
  const [tempName, setTempName] = useState(null);
  const [tempEmail, setTempEmail] = useState(null);
  const [tempPhone, setTempPhone] = useState(null);
  const [tempUserId, setTempUserId] = useState(null);
  const [sampleColor, setSampleColor] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const [reminderPreference, setReminderPreference] = useState('');

  const handleCheckoutName = (e) => {
    setCheckoutName({ name: e });
  };

  const handleNameAutoComplete = (e, data) => {
    if (data === null) {
      setCheckoutName({ name: '' });
    } else {
      setIsDisabled(true);
      setTempUserId(data.user_id);
      setTempName({ name: data.name });
      setTempEmail({ email: data.email });
      setTempPhone({ phone: data.phone });
      setCheckoutName({ name: data.name });
      setCheckoutEmail({ email: data.email });
      setCheckoutPhone({ phone: data.phone });
      
      if (data.contact_by_email == 1 && data.contact_by_phone == 1) {
        setReminderPreference('2');
      } else if (data.contact_by_email == 1) {
        setReminderPreference('0');
      } else if (data.contact_by_phone == 1) {
        setReminderPreference('1');
      } else {
        setReminderPreference('');
      }
    }
  };

  const handleCheckoutEmail = (e) => {
    setCheckoutEmail({ email: e });
  };

  const handleEmailAutoComplete = (e, data) => {
    if (data === null) {
      setCheckoutEmail({ email: '' });
    } else {
      setIsDisabled(true);
      setTempUserId(data.user_id);
      setTempName({ name: data.name });
      setTempEmail({ email: data.email });
      setTempPhone({ phone: data.phone });
      setCheckoutName({ name: data.name });
      setCheckoutEmail({ email: data.email });
      setCheckoutPhone({ phone: data.phone });

      if (data.contact_by_email == 1 && data.contact_by_phone == 1) {
        setReminderPreference('2');
      } else if (data.contact_by_email == 1) {
        setReminderPreference('0');
      } else if (data.contact_by_phone == 1) {
        setReminderPreference('1');
      } else {
        setReminderPreference('');
      }
    }
  };

  const handleCheckoutPhone = (e) => {
    setCheckoutPhone({ phone: e });
  };

  const handlePhoneAutoComplete = (e, data) => {
    if (data === null) {
      setCheckoutPhone({ phone: '' });
    } else {
      setIsDisabled(true);
      setTempUserId(data.user_id);
      setTempName({ name: data.name });
      setTempEmail({ email: data.email });
      setTempPhone({ phone: data.phone });
      setCheckoutName({ name: data.name });
      setCheckoutEmail({ email: data.email });
      setCheckoutPhone({ phone: data.phone });

      if (data.contact_by_email == 1 && data.contact_by_phone == 1) {
        setReminderPreference('2');
      } else if (data.contact_by_email == 1) {
        setReminderPreference('0');
      } else if (data.contact_by_phone == 1) {
        setReminderPreference('1');
      } else {
        setReminderPreference('');
      }
    }
  };

  const handleGuestCheckoutSubmit = () => {
    const productSkuId = sampleColor === '' || sampleColor === '0' ? null : sampleColor;
    const data = {
      userId: tempUserId,
      productId: props.productId,
      productSkuId: productSkuId,
      checkoutName: checkoutName.name,
      checkoutEmail: checkoutEmail.email,
      checkoutPhone: checkoutPhone.phone,
      contactByEmail: 0,
      contactByPhone: 0
    };

    switch (reminderPreference) {
      case '0':
        data.contactByEmail = 1;
        break;
      case '1':
        data.contactByPhone = 1;
        break;
      case '2':
        data.contactByEmail = 1;
        data.contactByPhone = 1;
        break;
    }

    guestCheckout(data).then(() => {
      getCustomerUserInfo()
        .then((result) => {
          setMessageType('success');
          setMessage('Guest checkout successful!');
          handleAlertOpen();
          props?.checkoutStatus();
        })
        .catch((err) => {
          setMessageType('error');
          setMessage('Error submitting guest checkout');
          handleAlertOpen();
        });
    });
  };

  const handleClear = () => {
    setIsDisabled(false);
    setCheckoutEmail({});
    setCheckoutName({});
    setCheckoutPhone({});
    setTempName(null);
    setTempEmail(null);
    setTempPhone(null);
    setTempUserId(null);
    setSampleColor('');
    setReminderPreference('');
  };

  const handleApproveCheckout = (productId, sampleCheckoutId) => {
    approveCheckout(productId, sampleCheckoutId)
      .then(() => {
        props?.checkoutStatus();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleReturn = (productId, sampleCheckoutId) => {
    checkin(productId, sampleCheckoutId)
      .then(() => {
        props?.checkoutStatus();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCheckedOut = (productId, sampleCheckoutId) => {
    checkin(productId, sampleCheckoutId)
      .then(() => {
        props?.checkoutStatus();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCancelCheckout = (sampleCheckoutId) => {
    cancelSampleCheckout(sampleCheckoutId)
      .then(() => {
        props?.checkoutStatus();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const canGuestCheckout = () => {
    if (checkoutName.name && (checkoutEmail.email || checkoutPhone.phone) && sampleColor !== '' && reminderPreference !== '') {
      return false;
    }
    return true;
  };

  return (
    <Dialog
      open={props.isOpen}
      onClose={() => {
        setSampleColor('');
        handleClear();
        props.onClose();
      }}
      maxWidth="xl"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div id="alert-dialog-title" style={{padding: "15px"}}>
        <Typography variant="h4" style={{display: "inline-block", verticalAlign: "middle", color: "#28C4FC"}}>
          <span className="product__hidden-header">Sample </span>Checkout
        </Typography>
        <div style={{display: "inline-block", verticalAlign: "middle", float: "right"}}>
          <Button
            variant="outlined"
            onClick={() => {
              setSampleColor('');
              handleClear();
              props.onClose();
            }}
            className="product__pending-header-button"
            style={{}}
          >
            X
          </Button>
        </div>
      </div>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={5} lg={3}>
            <List>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography variant="h5">Guest Checkout</Typography>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Style/>
                </ListItemIcon>
                <FormControl fullWidth>
                  <InputLabel shrink id="sample-selection-label">Sample Selection</InputLabel>
                  <Select
                    labelId="sample-selection-label"
                    id="sample-selection"
                    value={sampleColor}
                    variant="filled"
                    onChange={(e) => setSampleColor(e.target.value)}
                    displayEmpty
                  >
                    <MenuItem dense value="" disabled>Please select one of the sample options</MenuItem>
                    <MenuItem dense value="0">General Product Sample</MenuItem>
                    {props.options?.map((o) => {
                      return (
                        <MenuItem dense key={o.product_sku_uuid} value={o.product_sku_uuid}>
                          {o.color_name}
                          {o.discontinued ? (
                              <Tooltip title="Product discontinued">
                                <Info color="secondary" style={{marginLeft: "5px"}}/>
                              </Tooltip>
                            ) : undefined
                          }
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Person />
                </ListItemIcon>
                <Autocomplete
                  disabled={isDisabled}
                  freeSolo
                  fullWidth
                  value={tempName}
                  options={props.users}
                  getOptionLabel={(option) =>
                    option.name !== null ? option.name : ''
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Name"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password',
                      }}
                    />
                  )}
                  onInputChange={(e, data) => handleCheckoutName(data)}
                  onChange={handleNameAutoComplete}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Email />
                </ListItemIcon>
                <Autocomplete
                  disabled={isDisabled}
                  freeSolo
                  fullWidth
                  value={tempEmail}
                  options={props.users}
                  getOptionLabel={(option) =>
                    option.email !== null ? option.email : ''
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Email"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password',
                      }}
                    />
                  )}
                  onInputChange={(e, data) => handleCheckoutEmail(data)}
                  onChange={handleEmailAutoComplete}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Phone />
                </ListItemIcon>
                <Autocomplete
                  disabled={isDisabled}
                  freeSolo
                  fullWidth
                  value={tempPhone}
                  options={props.users}
                  getOptionLabel={(option) =>
                    option.phone !== null ? option.phone : ''
                  }
                  onInputChange={(e, data) => handleCheckoutPhone(data)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Phone"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password',
                      }}
                    />
                  )}
                  onChange={handlePhoneAutoComplete}
                />
              </ListItem>
              <ListItem>
                <FormControl component="fieldset">
                  <FormLabel component="legend"><Typography>Reminder Preference</Typography></FormLabel>
                  <RadioGroup aria-label="Reminder Preference" name="reminder-preference" row value={reminderPreference} onChange={(e) => setReminderPreference(e.target.value)}>
                    <FormControlLabel value="0" control={<Radio />} disabled={!tempEmail?.email && !checkoutEmail?.email} label="Email" labelPlacement='end'/>
                    <FormControlLabel value="1" control={<Radio />} disabled={!tempPhone?.phone && !checkoutPhone?.phone} label="Phone" labelPlacement='end'/>
                    <FormControlLabel value="2" control={<Radio />} disabled={(!tempEmail?.email || !tempPhone?.phone) && (!checkoutEmail?.email || !checkoutPhone?.phone)} label="Both" labelPlacement='end'/>
                  </RadioGroup>
                </FormControl>
              </ListItem>
              <ListItem>
                <Button
                  variant="contained"
                  autoFocus
                  style={{ margin: '10px 0px', marginRight: '20px' }}
                  onClick={handleClear}
                >
                  Clear
                </Button>
                <Button
                  variant="contained"
                  style={{
                    margin: '10px 0px',
                    backgroundColor: '#4caf50',
                    color: '#FFFFFF',
                  }}
                  onClick={handleGuestCheckoutSubmit}
                  disabled={canGuestCheckout()}
                >
                  Submit
                </Button>
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} md={7} lg={9} className="product__table-grid">
            <Typography variant="h5" style={{margin: "15px 0px"}}>Sample Checkouts</Typography>
            <TableContainer className="product__table-container">
              <Table aria-label="sample-table" stickyHeader>
                <TableHead>
                  <TableRow>
                    {header.map((column, index) => (
                      <TableCell key={index}>
                        <Typography className={classes.tableHeader}>
                          {column}
                        </Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.checkoutList.length > 0
                    ? props.checkoutList.map((row, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>{row.location_name}</TableCell>
                            <TableCell>{row.color_name}</TableCell>
                            <TableCell>{row.status_name}</TableCell>
                            <TableCell>{row.days_checked_out}</TableCell>
                            <TableCell>
                              {row.status_name === 'Pending' ? (
                                <Button
                                  className="product__icon-buttons"
                                  onClick={() =>
                                    handleApproveCheckout(
                                      props.productId,
                                      row.sample_checkout_id
                                    )
                                  }
                                >
                                  <Chip
                                    icon={
                                      <LibraryAddCheck
                                        style={{ color: '#EED202' }}
                                      />
                                    }
                                    label={row.status_name}
                                    variant="outlined"
                                    onDelete={() => {
                                      handleCancelCheckout(
                                        row.sample_checkout_id
                                      );
                                    }}
                                  />
                                </Button>
                              ) : row.status_name === 'Returned' ? (
                                <Button
                                  className="product__icon-buttons"
                                  onClick={() =>
                                    handleReturn(
                                      props.productId,
                                      row.sample_checkout_id
                                    )
                                  }
                                >
                                  <Chip
                                    icon={
                                      <LibraryAddCheck
                                        style={{ color: '#EED202' }}
                                      />
                                    }
                                    label={row.status_name}
                                    variant="outlined"
                                  />
                                </Button>
                              ) : row.status_name === 'Checked Out' ? (
                                <Button
                                  className="product__icon-buttons"
                                  onClick={() =>
                                    handleCheckedOut(
                                      props.productId,
                                      row.sample_checkout_id
                                    )
                                  }
                                >
                                  <Chip
                                    icon={
                                      <LibraryAddCheck
                                        style={{ color: '#2db83d' }}
                                      />
                                    }
                                    label={row.status_name}
                                    variant="outlined"
                                  />
                                </Button>
                              ) : (
                                <></>
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })
                    : ''}
                </TableBody>
              </Table>
            </TableContainer>

            <div className="product__list-container">
              {props.checkoutList?.map((row, index) => {
                  return (
                    <Accordion key={row.sample_checkout_id + '-mobile'} style={{margin: "1em auto"}}>
                      <AccordionSummary
                        expandIcon={<ExpandMore style={{color: "#28c4fc"}}/>}
                        aria-controls="sample-content"
                        id="sample-header"
                        style={{backgroundColor: "#142e3e", color: "#28c4fc"}}
                      >
                        <Typography style={{marginBottom: "0px"}}>
                          <b>{row.name}</b>
                          <br></br>
                          {row.color_name ?? 'General Product Sample'}
                        </Typography>
                        <Typography style={{marginBottom: "0px"}}></Typography>
                      </AccordionSummary>
                      <AccordionDetails style={{display: "block", backgroundColor: "rgba(0, 0, 0, 0.05)"}}>
                        <Typography style={{marginBottom: "0px"}}><b>{row.name}</b></Typography>
                        <Typography>{row.location_name}</Typography>
                        <Typography>Color/Option: {row.color_name ?? 'General Product Sample'}</Typography>
                        <Typography>Status: {row.status_name}</Typography>
                        <Typography>Location: {row.location_name ?? ''}</Typography>
                        {row.status_name === 'Pending' ? (
                          <Button
                            className="product__icon-buttons"
                            style={{padding: "0px", margin: "1em 0em 0em"}}
                            onClick={() =>
                              handleApproveCheckout(
                                props.productId,
                                row.sample_checkout_id
                              )
                            }
                          >
                            <Chip
                              icon={
                                <LibraryAddCheck
                                  style={{ color: '#EED202' }}
                                />
                              }
                              label={row.status_name}
                              variant="outlined"
                              onDelete={() => {
                                handleCancelCheckout(
                                  row.sample_checkout_id
                                );
                              }}
                            />
                          </Button>
                        ) : row.status_name === 'Returned' ? (
                          <Button
                            className="product__icon-buttons"
                            style={{padding: "0px", margin: "1em 0em 0em"}}
                            onClick={() =>
                              handleReturn(
                                props.productId,
                                row.sample_checkout_id
                              )
                            }
                          >
                            <Chip
                              icon={
                                <LibraryAddCheck
                                  style={{ color: '#EED202' }}
                                />
                              }
                              label={row.status_name}
                              variant="outlined"
                            />
                          </Button>
                        ) : row.status_name === 'Checked Out' ? (
                          <Button
                            className="product__icon-buttons"
                            style={{padding: "0px", margin: "1em 0em 0em"}}
                            onClick={() =>
                              handleCheckedOut(
                                props.productId,
                                row.sample_checkout_id
                              )
                            }
                          >
                            <Chip
                              icon={
                                <LibraryAddCheck
                                  style={{ color: '#2db83d' }}
                                />
                              }
                              label={row.status_name}
                              variant="outlined"
                            />
                          </Button>
                        ) : (
                          <></>
                        )}
                      </AccordionDetails>
                    </Accordion>
                  )
                })
              }
            </div>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};